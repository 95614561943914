import { PageProps } from 'gatsby';
import { MediaData } from 'packages/innedit';
import React, { FC } from 'react';

import Form from '~/containers/Espace/Form';
import params from '~/params/media.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageMediasUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { mediaId },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <div className="mx-6">
      <Form
        displayArchive={false}
        displayHidden
        displayView={false}
        docId={mediaId}
        model={
          new MediaData({
            params,
            espaceId: espace.id,
          })
        }
        type="update"
      />
    </div>
  </TemplateEspace>
);
export default requireEspace(PageMediasUpdate);
